import Sidebar from "../components/Sidebar/Sidebar";
import React, { useContext } from "react";
import BusinessDetails from "../components/BusinessDetails/BusinessDetails";
import BusinessesList from "../components/BusinessesList/BusinessesList";
import { BusinessContext } from "../contexts/BusinessContext";
import { motion, AnimatePresence } from "framer-motion";
import businessesInfo from "../config/businessesInfo.json";

export default function MainPage() {
  const {
    selectedBusiness,
    isFiltering,
    setFilteredBusinesses,
    setBusinesses,
  } = useContext(BusinessContext);

  return (
    <>
      <div>
        <main className="lg:pl-96">
          <AnimatePresence mode="wait">
            {" "}
            {selectedBusiness ? (
              <motion.div
                key="businessDetails"
                initial={{ opacity: 0, filter: "blur(5px)" }}
                animate={{ opacity: 1, filter: "blur(0px)" }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.15 }}
              >
                <BusinessDetails />
              </motion.div>
            ) : (
              <motion.div
                key="businessesList"
                initial={{ opacity: 0, filter: "blur(5px)" }}
                animate={{ opacity: 1, filter: "blur(0px)" }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.15 }}
              >
                <BusinessesList />
              </motion.div>
            )}
          </AnimatePresence>
          <motion.div
            className={`flex p-4 font-semibold text-white transition-colors duration-300 rounded-full cursor-pointer smallMobile:fixed horizontalTablet:hidden bg-primary bottom-3 right-3 left-3 hover:bg-primary/50 ${
              selectedBusiness ? "pointer-events-none" : ""
            }`}
            initial={{ opacity: 0 }}
            animate={{
              opacity: isFiltering && !selectedBusiness ? 1 : 0,
              filter:
                isFiltering && !selectedBusiness ? "blur(0px)" : "blur(5px)",
            }}
            onClick={() => {
              setBusinesses(businessesInfo);
              setFilteredBusinesses([]);
            }}
          >
            <p className="mx-auto">Limpar filtros</p>
          </motion.div>
        </main>
        <Sidebar />
      </div>
    </>
  );
}
