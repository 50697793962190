export class TimeHelper {
  constructor(business) {
    this.business = business;
    this.daysOfWeek = [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ];
  }

  GetWorkingHoursForToday() {
    const todayIndex = new Date().getDay();
    const todayName = this.daysOfWeek[todayIndex];

    const todayHours = this.business.workingHours.find(
      (day) => day.day === todayName
    );

    return todayHours ? todayHours.hours : "Fechado";
  }

  IsOpenNow() {
    const todayHours = this.GetWorkingHoursForToday();
    if (todayHours === "Fechado") {
      return false;
    }

    const [opening, closing] = todayHours.split(" às ");
    const now = new Date();

    const [openingHour, openingMinute] = opening.split(":").map(Number);
    const [closingHour, closingMinute] = closing.split(":").map(Number);

    const openingTime = new Date();
    openingTime.setHours(openingHour, openingMinute, 0);

    const closingTime = new Date();
    closingTime.setHours(closingHour, closingMinute, 0);

    return now >= openingTime && now <= closingTime;
  }
}
