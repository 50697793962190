import React, { createContext, useState, useEffect, useMemo } from "react";
import businessesInfo from "../config/businessesInfo.json";
import { SearchHelper } from "../utils/SearchHelper";

export const BusinessContext = createContext();

export const BusinessProvider = ({ children }) => {
  const [businesses, setBusinesses] = useState(businessesInfo);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [search, setSearch] = useState("");
  const [filteredProcedures, setFilteredProcedures] = useState(null);

  const searchHelper = useMemo(
    () => new SearchHelper(businesses),
    [businesses]
  );

  const specialities = searchHelper.getUniqueSpecialities();
  const appointments = searchHelper.getUniqueProcedures("appointment");
  const exams = searchHelper.getUniqueProcedures("exam");
  const lab = searchHelper.getUniqueProcedures("lab");
  const surgeries = searchHelper.getUniqueProcedures("dentist");

  const searchIn = selectedFilter.searchIn;

  useEffect(() => {
    if (selectedBusiness) {
      const procedures = searchHelper.getProceduresByTypeForBusiness(
        selectedBusiness.pricingTable,
        searchIn === "appointments"
          ? "appointment"
          : searchIn === "exams"
          ? "exam"          
          : searchIn === "lab"
          ? "lab"
          : searchIn === "surgeries"
          ? "dentist"
          : ""
      );
      setFilteredProcedures(procedures);
    } else {
      setFilteredProcedures(null);
    }
  }, [selectedBusiness, searchIn, searchHelper]);

  const isFiltering = filteredBusinesses.length !== 0;

  return (
    <BusinessContext.Provider
      value={{
        businesses,
        setBusinesses,
        selectedBusiness,
        setSelectedBusiness,
        selectedFilter,
        setSelectedFilter,
        search,
        setSearch,
        specialities,
        appointments,
        exams,
        lab,
        surgeries,
        filteredBusinesses,
        setFilteredBusinesses,
        filteredProcedures,
        searchHelper,
        isFiltering,
        searchIn,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};
