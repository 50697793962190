// Filters.jsx

import { useState } from "react";
import { Radio, RadioGroup } from "@headlessui/react";
import filters from "../../../config/filters.json";
import * as HeroIcons from "@heroicons/react/24/outline";
import SearchField from "../../SearchField/SearchField";
import { BusinessContext } from "../../../contexts/BusinessContext";
import { useContext } from "react";

export default function Filters() {
  const { isFiltering } = useContext(BusinessContext);

  const [needsSearch, setNeedsSearch] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});

  return (
    <fieldset className="mt-10">
      <legend className="text-sm font-semibold leading-6 text-slate-900 select-none">
        Selecione o que deseja filtrar
      </legend>
      <RadioGroup
        value={selectedFilter}
        onChange={setSelectedFilter}
        className="grid grid-cols-1 mt-6 gap-y-6"
      >
        {needsSearch && (
          <SearchField
            searchIn={selectedFilter.searchIn}
            onClose={() => setNeedsSearch(false)}
          />
        )}

        {filters.map((filter) => {
          const IconComponent = filter.icon ? HeroIcons[filter.icon] : null;

          return (
            <Radio
              key={filter.id}
              value={filter}
              aria-label={filter.title}
              onClick={() => {
                filter.searchIn && setNeedsSearch(true);
              }}
              className={`group relative flex cursor-pointer rounded-lg border transition-all duration-300 p-4 shadow-sm ${
                isFiltering
                  ? "focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
                  : "border-slate-300 ring-transparent"
              }`}
            >
              <span className="flex flex-1">
                <span className="flex flex-col">
                  <span className="block text-sm font-medium text-slate-900">
                    {filter.title}
                  </span>
                  <span className="flex items-center mt-1 text-sm text-slate-500">
                    {filter.description}
                  </span>
                </span>
                {IconComponent && (
                  <div className="absolute top-0 bottom-0 flex items-center justify-center right-3">
                    <IconComponent className="text-slate-500 size-6" />
                  </div>
                )}
              </span>
              <span
                aria-hidden="true"
                className={`pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent transition-all duration-300 group-data-[focus]:border ${
                  isFiltering && "group-data-[checked]:border-primary"
                }`}
              />
            </Radio>
          );
        })}
      </RadioGroup>
    </fieldset>
  );
}
