export default function BusinessPicture({ imagePath }) {
  return (
    <div
      className="relative flex mb-auto verticalTablet:h-[350px] smallMobile:h-[250px] z-10 bg-slate-500"
      style={{
        backgroundImage: `url(${imagePath})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="absolute bottom-0 left-0 right-0 h-[100px] z-10"
        style={{
          backgroundImage: "linear-gradient(to top, white, transparent)",
          pointerEvents: "none",
        }}
      ></div>
    </div>
  );
}
