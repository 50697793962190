export class SearchHelper {
  constructor(businesses) {
    this.businesses = businesses;
  }

  getUniqueSpecialities() {
    const allSpecialities = this.businesses.flatMap(
      (business) => business.specialities
    );
    const uniqueSpecialities = Array.from(new Set(allSpecialities));
    uniqueSpecialities.sort();

    return uniqueSpecialities.map((speciality, index) => ({
      id: index + 1,
      name: speciality,
    }));
  }

  getBusinessesBySpeciality(speciality) {
    const filteredBusinesses = this.businesses.filter((business) =>
      business.specialities.includes(speciality)
    );

    return filteredBusinesses;
  }

  getUniqueProcedures(procedureType) {
    const allProcedures = this.businesses.flatMap((business) =>
      business.pricingTable.filter(
        (procedure) => procedure.procedureType === procedureType
      )
    );

    const uniqueProcedures = Array.from(
      new Set(allProcedures.map((procedure) => procedure.title))
    );

    uniqueProcedures.sort();

    return uniqueProcedures.map((title, index) => ({
      id: index + 1,
      name: title,
    }));
  }

  getBusinessesByProcedure(procedure) {
    const filteredBusinesses = this.businesses.filter((business) =>
      business.pricingTable.some(
        (pricing) => pricing.title.toLowerCase() === procedure.toLowerCase()
      )
    );

    return filteredBusinesses;
  }

  getProceduresByTypeForBusiness(pricingTable, procedureType) {
    if (procedureType === "") {
      return pricingTable;
    }

    return pricingTable.filter(
      (procedure) => procedure.procedureType === procedureType
    );
  }

  getProcedureByTitle(procedureTitle, proceduresArray) {
    if (!procedureTitle || procedureTitle === undefined) {
      return null;
    }

    return [
      proceduresArray.find(
        (procedure) =>
          procedure.title.toLowerCase() === procedureTitle.toLowerCase()
      ),
    ];
  }
}
