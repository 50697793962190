import Filters from "./Filters/Filters";
import { BusinessContext } from "../../contexts/BusinessContext";
import { useContext, useEffect } from "react";

export default function Sidebar() {
  const { isFiltering } = useContext(BusinessContext);

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    if (isMobile) {
      if (!isFiltering) {
        // Quando o sidebar está visível, adiciona 'overflow-hidden' ao body
        document.body.classList.add("overflow-hidden");
      } else {
        // Quando o sidebar está oculto, remove 'overflow-hidden' do body
        document.body.classList.remove("overflow-hidden");
      }
    } else {
      // Remove 'overflow-hidden' se não for dispositivo móvel
      document.body.classList.remove("overflow-hidden");
    }

    // Limpa a classe 'overflow-hidden' ao desmontar o componente
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isFiltering]);

  return (
    <>
      <aside
        className={`fixed top-0 bottom-0 ${
          isFiltering
            ? "hidden"
            : "smallMobile:px-6 smallMobile:w-full smallMobile:h-lvh"
        } py-6 overflow-y-auto border-r border-slate-200 shadow-inner shad lg:w-96 lg:px-8 horizontalTablet:block bg-slate-50`}
      >
        <div>
          <img
            src={
              "https://royalmedlife.com.br/wp-content/uploads/2024/06/Prancheta-4@2x.png"
            }
            alt=""
            className="w-[300px] smallMobile:mx-auto"
          />
        </div>
        <Filters />
      </aside>
    </>
  );
}
