import {
  ArrowTopRightOnSquareIcon,
  PhoneArrowUpRightIcon,
} from "@heroicons/react/24/outline";
import { useContext } from "react";
import { BusinessContext } from "../../contexts/BusinessContext";
import { TimeHelper } from "../../utils/TimeHelper";
import { motion } from "framer-motion";

export default function Cards() {
  const { businesses, setSelectedBusiness, filteredBusinesses } =
    useContext(BusinessContext);

  const businessesToDisplay =
    filteredBusinesses.length > 0 ? filteredBusinesses : businesses;

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      {businessesToDisplay.map((business) => {
        const timeHelper = new TimeHelper(business);
        const todayHours = timeHelper.GetWorkingHoursForToday();
        const isOpenNow = timeHelper.IsOpenNow();

        return (
          <motion.div
            key={business.id}
            tabIndex={0}
            onClick={() => {
              setTimeout(() => {
                setSelectedBusiness(business);
              }, 150);
            }}
            className="relative flex items-center px-6 py-5 space-x-3 transition-all duration-300 bg-white border border-slate-300 rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary focus:ring-inset hover:border-slate-400"
            initial={{ opacity: 0, filter: "blur(5px)" }}
            animate={{ opacity: 1, filter: "blur(0px)" }}
            transition={{ duration: 0.3, delay: business.id * 0.1 }}
          >
            <div className="flex-1 min-w-0 mr-10">
              <div className="focus:outline-none">
                <span aria-hidden="true" className="absolute inset-0" />
                <p className="font-bold text-secondary text-md">
                  {business.businessName}
                </p>
              </div>
              <p className="text-sm text-slate-500 truncate">
                {business.businessAddress}
              </p>
              <p className="text-sm text-slate-500 truncate">
                Telefone: {business.businessPhone}
              </p>
              <p className="text-sm text-slate-500 truncate">
                <span
                  className={`font-bold ${
                    isOpenNow ? "text-primary" : "text-red-500"
                  }`}
                >
                  {isOpenNow ? "Aberto" : "Fechado"}
                </span>{" "}
                – {todayHours}
              </p>
              <p className="mt-3 text-sm font-semibold text-slate-500 truncate">
                Clique para ver detalhes
              </p>
            </div>
            <div className="absolute top-0 bottom-0 right-0 flex flex-col items-center justify-center px-6 space-y-6 align-middle">
              <ArrowTopRightOnSquareIcon className="text-slate-500 transition-colors duration-300 cursor-pointer size-6 hover:text-primary" />
              {/* <PhoneArrowUpRightIcon className="text-slate-500 transition-colors duration-300 cursor-pointer size-6 hover:text-primary sm:flex md:hidden" /> */}
            </div>
          </motion.div>
        );
      })}
    </div>
  );
}
