import MainPage from "./pages/MainPage";
import { BusinessProvider } from "./contexts/BusinessContext";

function App() {
  return (
    <BusinessProvider>
      <MainPage />
    </BusinessProvider>
  );
}

export default App;
