import {
  ClockIcon,
  GlobeAmericasIcon,
  MapIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import { useContext } from "react";
import { BusinessContext } from "../../../contexts/BusinessContext";
import { TimeHelper } from "../../../utils/TimeHelper";

export default function BusinessInfo() {
  const { selectedBusiness } = useContext(BusinessContext);
  const timeHelper = new TimeHelper(selectedBusiness);
  const todayHours = timeHelper.GetWorkingHoursForToday();

  return (
    <div className="pb-4 rounded-lg shadow-sm bg-slate-50 ring-1 ring-slate-900/5">
      <div className="flex flex-wrap">
        <div className="flex flex-none w-full px-6 mt-4 gap-x-4">
          <dt className="flex-none">
            <span className="sr-only">Horário de funcionamento</span>
            <ClockIcon aria-hidden="true" className="w-5 h-6 text-slate-400" />
          </dt>
          <dd className="text-sm leading-6 text-slate-500">
            Hoje: {todayHours}
          </dd>
        </div>
        <div className="flex flex-none w-full px-6 mt-4 gap-x-4">
          <dt className="flex-none">
            <span className="sr-only">Número de telefone</span>
            <PhoneIcon aria-hidden="true" className="w-5 h-6 text-slate-400" />
          </dt>
          <dd className="text-sm leading-6 text-slate-500">
            {selectedBusiness.businessPhone}
          </dd>
        </div>
        {Array.isArray(selectedBusiness.businessAddress) ? (
          selectedBusiness.businessAddress.map((address, index) => (
            <div
              key={index}
              className="flex flex-none w-full px-6 mt-4 gap-x-4 border-slate-900/5"
            >
              <dt className="flex-none">
                <span className="sr-only">Endereço</span>
                <MapIcon
                  aria-hidden="true"
                  className="w-5 h-6 text-slate-400"
                />
              </dt>
              <dd className="text-sm font-normal leading-6 text-slate-900">
                {address}
              </dd>
            </div>
          ))
        ) : (
          <div className="flex flex-none w-full px-6 mt-4 gap-x-4 border-slate-900/5">
            <dt className="flex-none">
              <span className="sr-only">Endereço</span>
              <MapIcon aria-hidden="true" className="w-5 h-6 text-slate-400" />
            </dt>
            <dd className="text-sm font-normal leading-6 text-slate-900">
              {selectedBusiness.businessAddress}
            </dd>
          </div>
        )}
        {selectedBusiness.businessUrl && (
          <div className="flex flex-none w-full px-6 mt-4 overflow-hidden gap-x-4 border-slate-900/5">
            <dt className="flex-none">
              <span className="sr-only">Site</span>
              <GlobeAmericasIcon
                aria-hidden="true"
                className="w-5 h-6 text-slate-400"
              />
            </dt>
            <dd className="text-sm font-normal leading-6 text-slate-900 truncate">
              <a
                href={selectedBusiness.businessUrl}
                target="_blank"
                rel="noreferrer"
                className="max-w-full overflow-hidden text-blue-600"
              >
                {selectedBusiness.businessUrl}
              </a>
            </dd>
          </div>
        )}
      </div>
      {/* <div className="px-6 py-6 mt-6 border-t border-slate-900/5">
        <a href="/#" className="text-sm font-semibold leading-6 text-slate-900">
          Abrir no mapa <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
      <div className="px-6 pb-6 border-slate-900/5 smallMobile:flex horizontalTablet:hidden">
        <a href="/#" className="text-sm font-semibold leading-6 text-slate-900 ">
          Ligar
        </a>
      </div> */}
    </div>
  );
}
