import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  DialogBackdrop,
} from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon as SecondaryMagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline";
import { useState, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { BusinessContext } from "../../contexts/BusinessContext";
import { SearchHelper } from "../../utils/SearchHelper";

export default function SearchField({ searchIn, onClose }) {
  const {
    businesses,
    setFilteredBusinesses,
    setSearch,
    setSelectedBusiness,
    specialities,
    appointments,
    exams,
    lab,
    surgeries,
    setSelectedFilter,
  } = useContext(BusinessContext);

  const [open, setOpen] = useState(false);
  const searchHelper = new SearchHelper(businesses);

  const allItems =
    searchIn === "appointments"
      ? appointments
      : searchIn === "exams"
      ? exams
      : searchIn === "lab"
      ? lab
      : searchIn === "surgeries"
      ? surgeries
      : specialities;
  const [query, setQuery] = useState("");

  // Adicione o estado visibleItems
  const [visibleItems, setVisibleItems] = useState(30);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 50);
  }, []);

  const filteredResults =
    query === ""
      ? []
      : allItems.filter((item) => {
          const normalizeString = (str) =>
            str
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .trim()
              .replace(/[-_]/g, " ")
              .replace(/[()]/g, "");

          const hasDiacriticalMarks = (str) =>
            /[áàâãäéèêëíìîïóòôõöúùûüçñ]/i.test(str);

          const normalizedQuery = normalizeString(query);
          const normalizedName = normalizeString(item.name);

          if (hasDiacriticalMarks(query)) {
            return query
              .toLowerCase()
              .split(" ")
              .every((word) => item.name.toLowerCase().includes(word));
          }

          return normalizedQuery
            .split(" ")
            .every((word) => normalizedName.includes(word));
        });

  // Calcule os itens a serem exibidos com base em visibleItems
  const itemsToDisplay = (query === "" ? allItems : filteredResults).slice(
    0,
    visibleItems
  );

  const handleSelectOption = (option) => {
    if (option && option.name) {
      const selectedOption =
        searchIn !== "specialities"
          ? searchHelper.getBusinessesByProcedure(option.name)
          : searchHelper.getBusinessesBySpeciality(option.name);
      setSearch(option);
      setFilteredBusinesses(selectedOption);
      setSelectedBusiness(null);
      setSelectedFilter({ searchIn });
      setQuery("");
      setOpen(false);
      setTimeout(() => {
        onClose();
      }, 300);
    } else {
      setFilteredBusinesses([]);
      setQuery("");
      setTimeout(() => {
        onClose();
      }, 300);
    }
  };

  // Implemente a função handleScroll
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const scrollPosition = scrollTop + clientHeight;
    const scrollThreshold = scrollHeight * 0.6;

    if (scrollPosition > scrollThreshold) {
      setVisibleItems((prevVisibleItems) => prevVisibleItems + 30);
    }
  };

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          className="relative z-10"
          open={open}
          onClose={() => {
            setOpen(false);
            setTimeout(() => {
              setQuery("");
              onClose();
            }, 300);
          }}
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-sm backdrop-filter transition-all data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen p-4 overflow-y-auto sm:p-6 md:p-20">
            <DialogPanel
              as={motion.div}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.3 }}
              className="z-30 max-w-2xl mx-auto overflow-hidden transition-all transform bg-white divide-y divide-slate-500 shadow-2xl divide-opacity-10 rounded-xl bg-opacity-80 ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter"
            >
              <Combobox onChange={handleSelectOption}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-slate-900 text-opacity-40"
                    aria-hidden="true"
                  />
                  <ComboboxInput
                    autoFocus
                    className="w-full h-12 pr-4 text-slate-900 bg-transparent border-0 pl-11 focus:ring-0 sm:text-sm"
                    placeholder="Buscar..."
                    onChange={(event) => {
                      setQuery(event.target.value);
                      setVisibleItems(30); // Reinicie visibleItems quando o usuário digitar uma nova consulta
                    }}
                    onBlur={() => setQuery("")}
                  />
                </div>

                {(query === "" || filteredResults.length > 0) && (
                  <ComboboxOptions
                    static
                    as="ul"
                    className="overflow-y-auto divide-y divide-slate-500 max-h-80 scroll-py-2 divide-opacity-10"
                    onScroll={handleScroll} // Adicione o evento onScroll aqui
                  >
                    <li className="p-2">
                      {query === "" && (
                        <>
                          <motion.div
                            className="px-6 py-8 text-center sm:px-14"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.7 }}
                          >
                            <SecondaryMagnifyingGlassIcon
                              className="mx-auto text-slate-400 size-6"
                              aria-hidden="true"
                            />
                            <motion.p
                              className="mt-4 text-sm select-none text-slate-900"
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 1 }}
                            >
                              Comece a digitar para buscar por um{" "}
                              {searchIn === "appointments"
                                ? "especialidade"
                                : searchIn === "exams"
                                ? "exame"
                                : searchIn === "lab"
                                ? "exame de laboratório"
                                : searchIn === "surgeries"
                                ? "cirurgia"
                                : "item"}
                              .
                            </motion.p>
                          </motion.div>
                        </>
                      )}
                      <ul className="text-sm text-slate-700">
                        {itemsToDisplay.map((item) => (
                          <ComboboxOption
                            as="li"
                            key={item.id}
                            value={item}
                            className="group flex select-none items-center rounded-md px-3 py-2 data-[focus]:bg-slate-900 data-[focus]:bg-opacity-5 data-[focus]:text-slate-900 cursor-pointer transition-all duration-200"
                          >
                            <MagnifyingGlassCircleIcon
                              className="h-6 w-6 flex-none text-slate-900 text-opacity-40 group-data-[focus]:text-opacity-100 transition-colors duration-200"
                              aria-hidden="true"
                            />
                            <span className="flex-auto ml-3 truncate">
                              {item.name}
                            </span>
                            <span className="ml-3 hidden flex-none text-slate-500 group-data-[focus]:inline transition-all duration-200">
                              Filtrar...
                            </span>
                          </ComboboxOption>
                        ))}
                      </ul>
                    </li>
                  </ComboboxOptions>
                )}

                {query !== "" && filteredResults.length === 0 && (
                  <div className="px-6 text-center py-14 sm:px-14">
                    <MagnifyingGlassCircleIcon
                      className="w-6 h-6 mx-auto text-slate-900 text-opacity-40"
                      aria-hidden="true"
                    />
                    <p className="mt-4 text-sm text-slate-900">
                      Infelizmente, não conseguimos encontrar nenhum resultado
                      com "{query}".
                    </p>
                  </div>
                )}
              </Combobox>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
}
