import BusinessPicture from "./BusinessPicture/BusinessPicture";
import BusinessInfo from "./BusinessInfo/BusinessInfo";
import PricingTable from "./PricingTable/PricingTable";
import { BusinessContext } from "../../contexts/BusinessContext";
import { useContext } from "react";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { SearchHelper } from "../../utils/SearchHelper";

export default function BusinessDetails() {
  const { selectedBusiness, setSelectedBusiness, search, searchHelper } =
    useContext(BusinessContext);
  if (!selectedBusiness) {
    return null;
  }

  const filteredSingleProcedure = searchHelper.getProcedureByTitle(
    search.name,
    selectedBusiness.pricingTable
  );

  return (
    <div className="relative">
      <div
        className="absolute z-20 flex items-center justify-center text-lg font-bold text-white transition-colors duration-300 rounded-full cursor-pointer select-none bg-black/40 top-2 left-3 size-10 backdrop-blur-lg backdrop-filter hover:bg-black/70"
        onClick={() => {
          setSelectedBusiness(null);
        }}
      >
        <ChevronLeftIcon className="size-6" />
      </div>
      <BusinessPicture imagePath={selectedBusiness.imagePath} />
      <p className="absolute z-10 gap-6 px-4 text-4xl font-semibold truncate -mt-7 lg:px-8 text-secondary">
        {selectedBusiness.businessName}
      </p>
      <div className="grid min-h-full grid-cols-1 gap-6 px-4 py-10 mt-3 sm:px-6 lg:px-8 lg:py-6 horizontalTablet:grid-cols-3">
        <div className="flex-1 col-span-1 ">
          <BusinessInfo />
        </div>
        <div className="flex-1 smallMobile:col-span-1 horizontalTablet:col-span-2">
          <PricingTable
            data={
              filteredSingleProcedure !== null
                ? filteredSingleProcedure
                : selectedBusiness.pricingTable
            }
          />
        </div>
      </div>
    </div>
  );
}
