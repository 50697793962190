import React, { useContext } from "react";
import { BusinessContext } from "../../../contexts/BusinessContext";

export default function PricingTable({ data }) {
  const { searchIn } = useContext(BusinessContext);
  console.warn("PricingTable", data);
  return (
    <table className="w-full text-sm leading-6 text-left whitespace-nowrap">
      <colgroup>
        <col className="w-full" />
        <col />
        <col />
        <col />
      </colgroup>
      <thead className="text-slate-900 border-b border-slate-200">
        <tr>
          <th scope="col" className="px-0 py-3 font-bold">
            {searchIn === "exams"
              ? "Exames"
              : searchIn === "surgeries"
              ? "Odontologia"
              : searchIn === "appointments"
              ? "Consultas e terapias"
              : searchIn === "lab"
              ? "Exames de laboratórios"
              : "Todos os procedimentos"}
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-0 font-bold text-right text-green-600 sm:table-cell "
          >
            Preço Conveniado
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id} className="border-b border-slate-100">
            <td className="px-0 py-5 align-top max-w-0">
              <div className="font-medium text-slate-900 truncate">
                {item.title}
              </div>
              <div className="text-slate-500 truncate">{item.description}</div>
            </td>
            <td className="py-5 pl-8 pr-0 font-bold text-right text-green-600 align-top tabular-nums">
              {item.discountValue}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
