import Cards from "../Cards/Cards";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { BusinessContext } from "../../contexts/BusinessContext";
import { useContext } from "react";
import businessesInfo from "../../config/businessesInfo.json";

export default function BusinessesList() {
  const {
    isFiltering,
    setBusinesses,
    setFilteredBusinesses,
    search,
    setSearch,
    setSelectedFilter,
  } = useContext(BusinessContext);

  return (
    <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
      <div className="flex">
        <p className="text-4xl font-semibold select-none text-primary mb-7">
          {isFiltering ? search.name : "Rede credenciada"}
        </p>

        <motion.div
          className={`flex p-2 ml-auto transition-colors duration-300 rounded-full cursor-pointer smallMobile:hidden horizontalTablet:block horizontalTablet:size-11 bg-primary hover:bg-primary/50 ${
            !isFiltering && "pointer-events-none"
          }`}
          initial={{ opacity: 0 }}
          animate={{
            opacity: isFiltering ? 1 : 0,
            filter: isFiltering ? "blur(0px)" : "blur(5px)",
          }}
          onClick={() => {
            setBusinesses(businessesInfo);
            setSelectedFilter({});
            setSearch("");
            setFilteredBusinesses([]);
          }}
        >
          <XMarkIcon className="text-white smallMobile:hidden horizontalTablet:block" />
        </motion.div>
      </div>
      <Cards />
    </div>
  );
}
